<template>
  <div>
    <div class="auditment">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in orderType"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === typeIndex ? 'active' : ''"
        >
          {{ item.code }}
        </div>
      </div>
      <div class="input-with">
        <el-input
          placeholder="请输入用户昵称"
          v-model="queryInfo.wechatUserName"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
        <!-- <el-button>批量导出</el-button> -->
      </div>
      <el-table
        v-loading="loading"
        @sort-change="changeSort"
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        />
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column label="编号" align="center" type="index">
          <template slot-scope="scope">
            <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信头像" align="center">
          <template>
            <img src="../../assets/image/Group 2821.png" width="42px" height="42px" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="微信昵称" prop="nickName" align="center"></el-table-column>
        <el-table-column label="姓名" prop="realName" align="center">
          <template v-slot="{ row }">
            <div style="display: flex; flex-direction: column">
              <span>{{ row.realName }}</span>
              <span v-if="row.reasonsRefusal" style="font-size: 12px; color: #999999"
                >理由:{{ row.reasonsRefusal }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column label="所属商会" prop="chamberCommerceName" align="center">
          <template v-slot="{ row }">
            <span>{{ row.chamberCommerceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商会职务" prop="job" align="center">
          <template v-slot="{ row }">
            <span>{{ row.job }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" align="center">
          <template v-slot="{ row }">
            <span>{{ row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="推荐人" prop="referrerName" align="center"></el-table-column>

        <el-table-column
          v-if="typeIndex == 0"
          label="申请时间"
          prop="createTime"
          align="center"
          sortable
        ></el-table-column>
        <el-table-column
          v-if="typeIndex == 0"
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="160px"
        >
          <template slot-scope="scope">
            <el-button style="color: #146aff" type="text" @click="torecord(scope.row, 1)"
              >通过
            </el-button>
            <el-button style="color: #ff4646" type="text" @click="torecords(scope.row, 0)"
              >不通过
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          v-if="typeIndex == 1"
          label="提交时间"
          prop="createTime"
          align="center"
        ></el-table-column>
        <el-table-column
          v-if="typeIndex == 1"
          label="审核人"
          prop="auditorName"
          align="center"
        ></el-table-column>

        <el-table-column
          v-if="typeIndex == 1"
          label="审核时间"
          prop="updateTime"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      title="通过审核"
      :visible.sync="dialogVisible"
      width="440px"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px" :model="form" :rules="formRules" ref="form">
        <el-form-item label="关联商会" prop="chamberCommerceName">
          <!-- <el-input v-model="form.chamberCommerceName" placeholder="请输入关键词检索商会" /> -->
          <el-autocomplete
            class="inline-input"
            v-model="form.chamberCommerceName"
            :fetch-suggestions="chamberCommerceNameQuerySearch"
            placeholder="请输入关键词检索商会"
            :trigger-on-focus="false"
            @input="chamberCommerceNameChanged"
            @select="chamberCommerceNameSelect"
          ></el-autocomplete>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCertified, putauditor, getApiChamberCommerceResults } from '@/api/wechatUser'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyName: null, //企业名称
  field: null, //排序字段
  projectName: null, //项目名称
  sort: 'DESC', //排序方式（ASC：正序，DESC：倒序）
  state: '0', //审核状态（0：待审核；1：已审核）
  wechatUserId: null, //用户id
  wechatUserName: null, //用户名称
  createTime: null
})
export default {
  name: 'auditment',
  data() {
    return {
      chamberCommerceNameList: [], // 查询出的商会列表
      formRules: {
        chamberCommerceName: [{ required: true, message: '请输入商会名称', trigger: 'blur' }]
      },
      form: {}, // 审核传参
      dialogVisible: false,
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      orderType: [
        {
          id: 0,
          code: '待审核'
        },
        {
          id: 1,
          code: '已审核'
        }
      ],
      typeIndex: 0
    }
  },
  created() {
    this.search()
  },
  methods: {
    storeClick(id) {
      this.queryInfo.state = id
      this.typeIndex = id
      this.search()
    },
    //获取数据列表
    async search() {
      const res = await getCertified(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
      }
    },
    //输入框变化
    chamberCommerceNameChanged() {
      this.chamberCommerceNameList = []
    },
    //选择商会
    async chamberCommerceNameSelect(item) {
      let keywords = item.value
      const { data: res } = await getApiChamberCommerceResults({ keywords })
      if (res.resultCode === 200) {
        this.form.chamberCommerceId = res.data[0].id
        this.form.chamberCommerceName = res.data[0].chamberCommerceName
        this.chamberCommerceNameList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询商会
    async chamberCommerceNameQuerySearch(str, cb) {
      if (str != '') {
        let keywords = str
        const { data: res } = await getApiChamberCommerceResults({ keywords })
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.chamberCommerceNameList.push({
              value: item.chamberCommerceName
            })
          })
          cb(this.chamberCommerceNameList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    //通过
    torecord(row, index) {
      this.form = { ...row }
      this.form.auditStatus = index
      this.dialogVisible = true
    },
    // 保存
    async submitForm() {
      const { data: res } = await putauditor(this.form)
      if (res.resultCode === 200) {
        this.dialogVisible = false
        this.search()
        this.$message.success('通过成功')
      }
      // this.$confirm('您是否确认通过？')
      //   .then(() => {})
      //   .catch(() => {})
    },
    //不通过
    torecords(row, index) {
      row.auditStatus = index
      this.$prompt('请输入理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          row.reasonsRefusal = value

          return putauditor(row)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.queryInfo.createTime = 'asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时
          this.queryInfo.createTime = 'desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.search()
        }
      }
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.auditment {
  background: #ffffff;
  padding: 20px;
  .store_table {
    width: 100%;
    overflow: hidden;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;

    .store_table_item {
      padding: 0px 20px;
      overflow: hidden;
      line-height: 48px;
      box-sizing: border-box;
      color: #1f2d3d;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
    }

    .active {
      border-bottom: 2px solid #4e93fb;
      color: #4e93fb;
      background-color: #fff;
    }
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;

    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
</style>
